<template>
  <div class="main-content">
    <div class="panel-wrapper">
      <div>
        <h1 class="headline">
          Invite users to account
        </h1>
        <h2 class="subline">
          You can invite users to join account and manage their access
        </h2>
      </div>
      <div>
        <button class="default-button" @click="openCreateModal()">
          + Add user
        </button>
      </div>
    </div>

    <div class="main-content-grid">
      <div class="grid">
        <div v-for="user in usersList" :key="user.id" :class="userClass">
          <div class="main-content-grid-item" :class="{opened: opened === user.id}" @click="openViewModal(user.id)">
            <div class="view-options" @click.stop="toggleMenu(user.id)">
              <span class="view-options-inner">&middot;&middot;&middot;</span>
              <span class="view-options-close" />
            </div>

            <div class="item-center" v-if="user.is_active">
              <div class="item-center-title">
                <span class="overflow-ellipses notranslate" :title="user.name">{{ user.name }}</span>
              </div>
              <div class="item-center-subtitle">
                <span class="overflow-ellipses notranslate" :title="user.email">{{ user.email }}</span>
              </div>
              <div class="item-copy item-center-desc yellow-colored">
                {{ getUserRole(user.role) }}
              </div>
            </div>

            <div class="item-center-validate" v-if="user.is_active">
              <span v-if="user.expired_at">Valid thru {{ user.expired_at | moment('DD MMM YYYY') }}</span>
              <span v-else>Indefinite access period</span>
            </div>

            <div class="item-center" v-if="!user.is_active">
              <div class="item-center-title item-gray">
                {{ user.name }}
              </div>
              <div class="item-copy item-center-desc item-red">
                Disabled
              </div>
            </div>

            <div class="main-content-expanded-options" @click.stop>
              <div class="options-wrapper">
                <div class="expanded-options-item">
                  <a href="#" @click.stop="openViewModal(user.id)" class="yellow-colored">View</a>
                </div>
                <div class="expanded-options-item">
                  <a href="#" @click.stop="openCreateModal(user.id)">Edit</a>
                </div>
                <div class="expanded-options-item">
                  <a href="#" @click.stop="toggleStatus(user.id, user.is_active)" class="red-colored">
                    {{ user.is_active ? 'Disable' : 'Enable' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="userClass">
          <div class="main-content-grid-item inactive" @click.stop="openCreateModal()">
            <div class="item-center-title" style="margin: 0">
              + Add user
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserCreateModal from './../management/UserCreateModal'
import UserViewModal from './../management/UserViewModal'
import ConfirmModal from "@/components/modals/ConfirmModal";
import {mapState} from "vuex";

export default {
  name: 'MultiUsers',
  data () {
    return {
      opened: false
    }
  },
  computed: {
    ...mapState({
      usersList: state => state.management.users,
      rolesList: state => state.management.roles,

      menuMainOpened: state => state.menu.isMenuMainOpened,
      menuSettingsOpened: state => state.menu.isMenuSettingsOpened
    }),
    userClass () {
      return (this.menuMainOpened && this.menuSettingsOpened) ? 'col-6' : 'col-4'
    }
  },
  sockets: {
    'updateUser' ({ id }) {
      this.$store.dispatch('management.update.user', { id })
    },
    'deleteUser' ({ id }) {
      this.$store.dispatch('management.delete.user', { id })
    },
    'createUser' ({ id }) {
      this.$store.dispatch('management.create.user', { id })
    }
  },
  created () {
    this.$store.dispatch('getManagementUsers')
    this.$store.dispatch('getManagementRolesList')
  },
  methods: {
    /**
     * Open view modal
     */
    openViewModal (id) {
      this.$modal.show(UserViewModal, {
        userId: id
      })
    },

    /**
     * Open modal window
     */
    openCreateModal (id) {
      this.$modal.show(UserCreateModal, {
        userId: id
      })
    },

    getUserRole(role) {
      if (this.rolesList) {
        const userRole = this.rolesList.find(i => {
          return i.id == role
        })

        if (userRole) {
          return userRole.label
        }
      }

      return ''
    },

    /**
     * Toggle user status
     * @param id - user id
     * @param status
     */
    toggleStatus: function (id, status) {
      const statusInt = Number(!status)

      const catchFunction = () => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      }

      const callFunction = (validate, code) => {
        return this.$sdk.management.update(id, {
          is_active: statusInt,
          code: code
        }, {
          params: {
            validate: validate
          }
        })
      }

      const successFunction = (response) => {
        const data = response.data

        if (Array.isArray(data) && data.length === 0) {
          if (statusInt) {
            this.$notice.success('User has been successfully enabled')
          } else {
            this.$notice.success('User has been successfully disabled')
          }
        }

        return response
      }

      if (this.$store.getters.isMfaEnabled) {
        this.$modal.show(ConfirmModal, {
          submit: ({code}) => {
            return callFunction(0, code)
                .then(successFunction)
          }
        })
      } else {
        return callFunction(0)
            .then(successFunction)
            .catch(catchFunction)
      }
    },

    /**
     * Toggle menu item
     *
     * @param id
     */
    toggleMenu: function (id) {
      if (this.opened === id) {
        this.opened = false
      } else {
        this.opened = id
      }
    }
  }
}
</script>

<style lang="less">
  .overflow-ellipses {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  .item-center {
    padding: 0 20px;

    &-title {
      color: #fff;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
    }
    &-subtitle {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
    }
    &-desc {
      font-size: 15px;
    }
    &-validate {
      font-size: 13px;
      color: #757576;

      text-align: center;
      position: absolute;
      left: 0; bottom: 30px;
      width: 100%;
    }
  }

  .item {
    &-gray {
      color: #757576;
    }
    &-red {
      color: #EA4C17 !important;
    }
  }
</style>
