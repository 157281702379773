<template>
  <div class="modal small" v-cloak v-if="user" v-body-scroll-lock="true">
    <div class="modal-content">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <div class="modal-content-section">
        <h3 class="modal-content-title">User details</h3>
        <div class="modal-content-inner">
          <div class="grid">
            <div class="col-6">
              <p class="modal-inner-title">
                Name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ user.name }}
              </p>
            </div>
            <div class="col-6">
              <p class="modal-inner-title">
                Email
              </p>
              <p class="modal-inner-copy notranslate">
                {{ user.email }}
              </p>
            </div>
            <div class="col-6">
              <p class="modal-inner-title">
                Expiration date
              </p>
              <p class="modal-inner-copy">
                <span v-if="user.expired_at">
                  {{ user.expired_at | moment('DD MMM YYYY') }}
                </span>
                <span v-else>
                  Indefinite access period
                </span>
              </p>
            </div>
            <div class="col-6">
              <p class="modal-inner-title">
                Access options
              </p>
              <p class="modal-inner-copy">
                {{ userRoleName }}
              </p>
            </div>
            <div class="col-6">
              <p class="modal-inner-title">
                Status
              </p>
              <p class="modal-inner-copy">
                {{ user.is_active ? 'Active' : 'Inactive' }}
              </p>
            </div>
          </div>
        </div>

        <div class="modal-content-bottom-panel">
          <div class="pull-right">
            <button @click="close" class="next-form-button">
              <icon name="icp_close" />
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Icon from "@/libs/Icon";
import {mapState} from "vuex";

export default {
  name: 'UserViewModal',
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  components: {
    Icon,
  },
  asyncComputed: {
    user () {
      return this.$sdk.management.view(this.userId).then(response => response.data)
    },
  },
  created() {
    this.$store.dispatch('getManagementRolesList')
  },
  computed: {
    ...mapState({
      rolesList: state => state.management.roles
    }),
    userRoleName () {
      const role = this.rolesList.find(item => {
        return item.id === this.user.role
      })

      return role ? role.label : 'Unknown'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
