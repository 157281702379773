<template>
  <div v-cloak class="modal small" v-body-scroll-lock="true">
    <div class="modal-content">
      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content-section">
        <h3 class="modal-content-title">
          {{ !isUpdateForm ? 'Add user' : 'Edit user' }}
        </h3>
        <form @submit.prevent="submit">
          <div class="modal-content-inner">
            <div class="grid">
              <div class="col-6">
                <form-group label="Name" :error="errors.name">
                  <input
                      type="text"
                      v-model="form.name"
                      :class="{'valid': form.name}"
                      @change="validate('name')"
                      class="form-control"
                  >
                </form-group>
              </div>
              <div class="col-6" v-if="!isUpdateForm">
                <form-group label="Email" :error="errors.email">
                  <input
                      type="text"
                      v-model="form.email"
                      :class="{'valid': form.email}"
                      @change="validate('email')"
                      class="form-control"
                  >
                </form-group>
              </div>
              <div class="col-6" v-if="!isUpdateForm">
                <form-group label="Phone" :error="errors.phone">
                  <input
                      type="text"
                      v-model="form.phone"
                      :class="{'valid': form.phone}"
                      class="form-control"
                      @change="validate('phone')"
                  >
                </form-group>
              </div>
              <div class="col-6">
                <form-group label="Expire date" :error="errors.expired_at">
                  <datepicker
                      v-model="form.expired_at"
                      value-type="timestamp"
                      format="DD-MM-YYYY"
                      placeholder="Choose expire date"
                      :lang="lang"
                      :class="{'valid': form.expired_at}"
                      @change="validate('expired_at')"
                  />
                </form-group>
              </div>
              <div class="col-6">
                <form-group label="Access options" :error="errors.role">
                  <multiselect
                      v-model="form.role"
                      :class="{'valid': form.role}"
                      :options="rolesList"
                      :allow-empty="false"
                      :preselect-first="true"
                      :show-labels="false"
                      label="label"
                      @close="validate('role')"
                  />
                </form-group>
              </div>
            </div>
          </div>

          <template v-if="$store.getters.isMfaEnabled">
            <h3 class="modal-content-title">Two-Factor authentication</h3>
            <h4 class="modal-content-subtitle">Enter the 6-digit verification code to confirm.</h4>
            <div class="modal-content-inner" >
              <div class="grid">
                <div class="col-6">
                  <form-group label="2FA code" :error="errors.code">
                    <code-input v-model="form.code" @input="validate('code')"/>
                  </form-group>
                </div>
              </div>
            </div>
          </template>


          <div class="modal-content-bottom-panel">
            <div class="pull-right">
              <p class="modal-content-bottom-panel-copy next-form-button" @click="submit">
                <span>Save</span>
                <icon name="icp_check" />
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/libs/Icon'
import Multiselect from 'vue-multiselect'
import FormGroup from '../../libs/forms/FormGroup'
import {mapGetters, mapState} from 'vuex'
import Datepicker from 'vue2-datepicker'
import CodeInput from "@/libs/CodeInput";

export default {
  name: 'UserCreateModal',
  components: {
    CodeInput,
    Multiselect,
    FormGroup,
    Datepicker,
    Icon
  },
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      /** Config for DatePicker **/
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      },

      form: {
        name: null,
        phone: null,
        email: null,
        role: null,
        expired_at: null,
        code: ''
      },

      errors: {
        name: null,
        phone: null,
        email: null,
        role: null,
        expired_at: null,
        code: null
      }
    }
  },
  watch: {
    'errors': {
      handler (newVal) {
        if (newVal?.code && this.form.code.length === 6) {
          this.form.code = ''
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.data,
      rolesList: state => state.management.roles
    }),

    isUpdateForm () {
      return !!this.userId
    },

    formData () {
      return {
        phone: this.form.phone ?? '',
        email: this.form.email ?? '',
        role: this.form.role ? this.form.role.id : '',
        name: this.form.name ?? '',
        expired_at: this.form.expired_at ? this.$moment(this.form.expired_at).utc(true).unix() : '',
        code: this.form.code ?? ''
      }
    }
  },
  created () {
    if (this.isUpdateForm) {
      this.$sdk.management.view(this.userId).then(response => {
        const data = response.data

        this.form.name = data.name
        this.form.expired_at = data.expired_at ? (data.expired_at * 1000) : null
        this.form.role = this.rolesList.find(function (item) {
          return item.id === data.role
        })
      })
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    validate (attribute) {
      this.submit(attribute)
    },
    submit (attribute = false) {
      const isValidate = typeof attribute === 'string' && attribute !== false
      let promise = null
      const config = {
        params: {}
      }

      if (isValidate) {
        config.params.validate = 1
      }

      if (this.isUpdateForm) {
        promise = this.$sdk.management.update(this.userId, this.formData, config)
      } else {
        promise = this.$sdk.management.invite(this.formData, config)
      }

      promise.then(response => {
        const count = Object.keys(response.data).length
        if (isValidate) {
          if (count > 0) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        } else {
          if (count > 0) {
            this.errors = response.data
          } else {
            this.close()
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
  .mcbp {
    &-margin {
      margin-right: 3px;
    }

    &-red {
      color: #ea4d17;
    }
  }
</style>
